<template>
  <div class="d-flex">
    <vs-select v-show="showSelect"
      multiple
      v-model="selectedUser">
      <vs-select-item class="selectUserItem" :key="index" :value="item.value" :text="item.text" v-for="(item,index) in possibleMember" />
    </vs-select>
    <span v-show="!showSelect">{{$t('GROUPADMIN.NO_MEMBER')}}</span>
    <vs-button v-show="showSelect" :disabled="!changed" class="ml-1" size="small" icon="save" @click="SaveMember"></vs-button>
  </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';

export default {
  name: "GroupMemberSelect",
  props:['GroupFk', 'Member'],
   data: function (){
     return {
      selectedUser:[],
      showSelect:true,
      selectedOld:"",
      changed:false
     }
  },
  mounted () {
      this.GetPossibleMember(this.GroupFk);
      this.selectedUser = [];
      this.Member.forEach(element => {
        this.selectedUser.push(element.userguid);
      });
      this.selectedOld = JSON.stringify(this.selectedUser);
  },
  computed:{

    possibleMember(){

      var ret = [];
      if(this.$store.state.group.possiblemember.data != null)
      {
        for (let i = 0; i < this.$store.state.group.possiblemember.data.length; i++)
        {     
          var val = {"text": this.$store.state.group.possiblemember.data[i].firstname + ' ' + this.$store.state.group.possiblemember.data[i].lastname,
          "value":this.$store.state.group.possiblemember.data[i].userguid};
          ret.push(val);
        }        
      }
      return ret;
    }

  },
  methods:{
    ...helperMethods,
    RefreshData(){
      this.GetPossibleMember(this.GroupFk);
    },
    SaveMember(){

      var data = {id:this.GroupFk,member:this.selectedUser};

      this.$store.dispatch('group/updateGroupMember', data)
        .then(response => {
          if(response.success)
          {
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('COMPANY_GROUP.MEMBER.SUCCESS.UPDATE')}); 
            this.$emit("Success");
          }
          else
          {
            var text = this.$t('COMPANY_GROUP.MEMBER.WARNING.UPDATE').replace("*1*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('COMPANY_GROUP.MEMBER.ERROR.UPDATE').replace("*1*",error)});  
            this.showSelect = false;
        });
    },
    GetPossibleMember(id){

      this.$store.dispatch('group/getPossibleMember', id);
    }
   
  },
  watch:{
    selectedUser(value){
      this.changed = this.selectedOld != JSON.stringify(value);
    }

  }
};
</script>
<style lang="css">
.selectUserItem > button > span{
    margin-left:7px;
}
</style>