<template>
    <div>
      <vs-prompt
      @cancel="canceld"
      @accept="accepted"
      @close="closed"
      :active.sync="dlgActive"
      :title="popupTitle"
      :accept-text="$t('COM.OK')"
      :cancel-text="$t('COM.CANCEL')"
      id="promptAddGroup"
      :is-valid="formValid">
       <div>
        <div>
          <p>{{$t('GROUPADMIN.ADD_GROUP.HEADER.TEXT')}}</p>
          <div class="default-input clearfix align-items-center mb-3">
            <vs-input :label="$t('GROUPADMIN.ADD.LABEL.BEZEICHNUNG')"  class="float-left mr-2 inputx" :placeholder="$t('GROUPADMIN.ADD.LABEL.BEZEICHNUNG')"  v-model="name"  :danger="name_fail" val-icon-danger="clear" />   
          </div>
          <div class="default-input d-flex align-items-center">
            <vs-select v-model="parent_group_fk" class=""  :label="$t('GROUPADMIN.ADD.LABEL.UEBERGORDNETE_GRUPPE')">
              <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in groupsForSelect" />
            </vs-select>
          </div>
        </div>
       </div>
     </vs-prompt>
    </div>
</template>
<script>

import helperMethods from '../../../helper/staticFuncHelper';
import codeHelper from '../../../helper/staticCodeHelper';
import enumHelper from '../../../helper/enumHelper';

export default {
  name: "AddGroupDlg",
  props:[],
  components:{
    },
  data: function (){
     return {
      dlgActive:false,
      popupTitle:this.$t('GROUPADMIN.ADD.HEADER'),
      id:0,
      parent_group_fk:-1,
      name:"",
      name_fail:false,
      name_ok:false,
      member:[],
      groupRights:[]

    }
  }, 
  mounted () {
    
    this.ClearForm();
    
  },
  methods:{
      ...helperMethods,
      ...codeHelper,
      ...enumHelper,
      
      ShowDlg(){
        this.ClearForm();
        this.dlgActive=true;
      },
      InitDlg(data)
      {
        this.ClearForm();
        this.id = data.id;
        this.parent_group_fk = data.parent_group_fk;
        this.name = data.name;
        this.member = data.member;
        this.groupRights = data.groupRights;
        this.popupTitle = this.$t('GROUPADMIN.EDIT.HEADER');
        this.dlgActive=true;
      },
      closed(){
        this.ClearForm();
      },
      accepted(){

          var data = {
            id:this.id,
            parent_group_fk: this.parent_group_fk,
            name: this.name,
            member:[],
            groupRights:[]
          };

          this.member.forEach(mem => {
            data.member.push(mem.userguid);
          });

          this.groupRights.forEach(mem => {
            data.groupRights.push(mem);
          });

          this.$store.dispatch('group/saveGroup', data)
          .then(response => {
            if(response.success === true)
            {
                this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('GROUPADMIN.ADD.SUCCESS.SAVE')});  
                this.$emit('Success',response.id);
            }
            else
            {
              var text = this.$t('GROUPADMIN.ADD.WARNING.SAVE');
              text = text.replace("*1*",this.$t(response.text));
              this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
            }
            
          }, 
          error =>{
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('GROUPADMIN.ADD.ERROR.SAVE') + error});  
          });

          this.ClearForm();
      },
      canceld(){
        this.ClearForm();
      },
      ClearForm(){ 
        this.popupTitle = this.$t('GROUPADMIN.ADD.HEADER');
        this.id = 0;
        this.parent_group_fk = -1;
        this.name = "";
        this.name_fail = false;
        this.name_ok = false;
        this.member = [],
        this.groupRights =[]
      },
  },
  computed:{
    formValid(){
      return this.name_ok;
    },
    groupsForSelect(){
      var ret = [];
      if(this.$store.state.group.groups.data != null)
      {
        this.$store.state.group.groups.data.forEach(element => {
          if(element.id != this.id)
          {
            ret.push({"text": element.name, "value":element.id});
          }
        });
      }
      return ret;
    }
  },
  watch:{
    name(value)
    {
        this.name_fail = value.length == 0;
        this.name_ok = !this.name_fail;
    },
  }
};
</script>
<style>
div#promptAddGroup > div.vs-dialog{
  max-width: 500px !important;
}
</style>