<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('GROUPADMIN.HEADER')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('GROUPADMIN.HEADER.TEXT')}}</span>
        </p>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
          <div class="d-flex align-items-center">
            <vs-button color="primary" type="filled" @click="AddGroup" icon="supervisor_account">{{$t('GROUPADMIN.BUTTON.ADD_GROUP')}}</vs-button>
          </div> 
        <div id="companygroup_table" class="vs-con-loading__container" >
          
          <vs-table
            search
            :data="groups"
            :noDataText="$t('GROUPADMIN.NO_DATA')"
            stripe
            max-items="25" 
            pagination
            hoverFlat>

            <template slot="header">
              <h3>
                {{$t('GROUPADMIN.TABLE.HEADER')}}
              </h3>
            </template>
            <template slot="thead">                               
              <vs-th sort-key="name">
                {{$t('GROUPADMIN.TABLE.COL1')}}
              </vs-th>
              <vs-th sort-key="parent_group_fk">
                {{$t('GROUPADMIN.TABLE.COL2')}}
              </vs-th> 
              <vs-th >
                {{$t('GROUPADMIN.TABLE.COL3')}}
              </vs-th>                                                                                                       
              <vs-th></vs-th>                                                                                                                                             
            </template>

            <template slot-scope="{data}">
              <vs-tr :data="tr"  :key="indextr" v-for="(tr, indextr) in data" >   
                <vs-td :data="tr.name">
                  {{tr.name}}
                </vs-td>  
                <vs-td :data="tr.parent_group_fk">
                  {{groupParentName(tr.parent_group_fk) }}
                </vs-td>   
                <vs-td>
                    <GroupMemberSelect :GroupFk="tr.id" :Member="tr.member" v-on:Success="LoadData"></GroupMemberSelect>
                </vs-td>                                                                                                            
                <vs-td>
                  <vs-button  @click="EditGroupRights(tr.id)" class="mr-2" size="small" color="primary" type="filled" icon="open_in_new"></vs-button> 
                  <vs-button  @click="EditGroup(tr)" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>  
                  <vs-button  @click="DeleteGroup(tr)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                </vs-td>                    
                                                                                                                                                               
              </vs-tr>
            </template>
          </vs-table>
          <vs-popup fullscreen :title="$t('GROUPADMIN.GROUP_RIGHT_TABLE_POPUP')" :active.sync="popupActive">
            <GroupRightsTable :isCompanyGroup="false" ref="groupRightsTable"></GroupRightsTable>
          </vs-popup>
          <AddGroupDlg ref="addGrpDlg" v-on:Success="LoadData"></AddGroupDlg>
        </div> 
      </vs-card>
    </vs-col>
  </vs-row>
</div>
</template>
<script>
import GroupMemberSelect from './components/group/GroupMemberSelect.vue';
import AddGroupDlg from './components/dialogs/AddGroupDlg.vue';
import GroupRightsTable from './components/group/GroupRightsTable.vue';

export default {
  name: "GroupAdmin",
  components: {
    GroupMemberSelect,
    AddGroupDlg,
    GroupRightsTable
  },
  data: function (){
      return {
      groups:[],
      popupActive:false
      };
  },
  mounted () {
        this.LoadData();
    },
  computed: 
  {
    getTableData() {

        var data = [];

        if(this.$store.state.group.groups.data != null)
        {
          data = this.$store.state.group.groups.data;
        }

        return data;
    },
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.group.groups.status) != 'undefined')
        loading = this.$store.state.group.groups.status.loading;
      return loading;
    },
  },
  watch: {
      getTableData(value) {
          this.$data.groups = value;
      },
      loadingTableData(value)
      {
        if(value)
        {
          this.$store.dispatch('alertqueue/showLoader', {'id':'#group_list'});
        }
        else
        {
          this.$store.dispatch('alertqueue/hideLoader', {'id':'#group_list'});
        }
      }
    },
  methods: {
      LoadData: function ()
      {
        this.$store.dispatch('group/getGroups', { root: true },{ root: true });
        this.$store.dispatch('group/getPossibleMember', { root: true },{ root: true });
      },
      EditGroup(data){
        this.$refs.addGrpDlg.InitDlg(data);
      },
      EditGroupRights(id){
        this.popupActive = true;
        this.$refs.groupRightsTable.LoadGroupRights(id);
      },
      AddGroup: function (){
        this.$refs.addGrpDlg.ShowDlg();
      },
      groupParentName(parentfk){
        return this.GetGroupVal(parentfk,'name');
      },
      GetGroupVal: function (id,prop){
        var ret = "";
        if(this.$store.state.group.groups.data != null)
        {
            for (let i = 0; i < this.$store.state.group.groups.data.length; i++)
            {
                if(this.$store.state.group.groups.data[i].id == id)
                {
                    ret = this.$store.state.group.groups.data[i][prop];
                    break;
                }
            }
        }
        return ret;
      }, 
      DeleteGroup(data){
        if(this.IsParentForOtherGroup(data.id))
        {
            this.$vs.dialog({
                    color: "primary",
                    title:this.$t('GROUPADMIN.DLG.HEADER.LOESCHEN_NICHT_MOEGLICH'),
                    acceptText: this.$t('COM.OK'),
                    text: this.$t('GROUPADMIN.DLG.TEXT.LOESCHEN_NICHT_MOEGLICH'),
                    accept: this.acceptAlert
                });
        }
        else
        {
            this.OpenConfirmDeleteGroup(data.id,data.name);
        }
      },      
      OpenConfirmDeleteGroup(id,name) {
          this.$vs.dialog({
            type: "confirm",
            color: "primary",
            title: this.$t('GROUPADMIN.DLG.HEADER.LOESCHEN'),
            acceptText: this.$t('COM.DELETE'),
            cancelText:this.$t('COM.CANCEL'),
            text: this.$t('GROUPADMIN.DLG.TEXT.LOESCHEN').replace("*1*",name),
            accept: this.SendDeleteGroupRequest,
            parameters:{"id": id,"name": name}
          });
        
      },
      SendDeleteGroupRequest: function(parameters){
          this.$store.dispatch('group/deleteGroup', parameters)
      },
      IsParentForOtherGroup(id){
        var retVal = false;
        if(this.$store.state.group.groups.data != null)
        {
            for (let i = 0; i < this.$store.state.group.groups.data.length; i++)
            {
                if(this.$store.state.group.groups.data[i].parent_group_fk == id)
                {
                    retVal = true;
                    break;
                }
            }
        }
        return retVal;
    }
  }
};

</script>